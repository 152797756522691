<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">

        <userleft currindex=4 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">我的发布
              </h3>
              <div class="info-cnt" style="margin-top:30px">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="求职" name="first">

                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>

              <div class="title intotitle">
                <h2>二手房</h2>
                
              </div>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="出售" name="first">
                      <secondhouselist />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="出租" name="first">
                        <rentinghouselist />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="求购" name="first">

                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="求租" name="first">

                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
import { getCookieByName } from '@/utils/helper';
import rentinghouselist from '../house/rentinghouselist';
import secondhouselist from '../house/secondhouselist';
var token = getCookieByName('access_token');
import { candidateId } from '@/api/jobwant';
import {
  usergetcurruserinfo,
  utilsgetuserqrcodebase64,
  usermodifynickname,
  usersendchangeusermobilesms,
  usermodifyavatar,
  professioncertlist,
  sendprofessioncertlogoutverifysms,
  sendprofessioncertreglogoutverifysms,
  professioncertlogout,
  professioncertreglogout,
} from '@/api/user';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
    rentinghouselist:rentinghouselist,
    secondhouselist:secondhouselist,
  },
  data() {
    return {
      dialogVisible: false,
      title: '专业资格注销',
      promsend: {
        VerifyCode: '',
        Id: '',
      },
      fileList: [],
      form: {
        userNickName: '',
        userAvatar: '',
        userAvatarUrl: '',
      },
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      iseditusername: true,
      iseditmobile: true,
      verifCode: '',
      codeMsg: '获取验证码',
      bullet: 1,
      second: 60,
      timer: null,
      professioncertlist: [],
      activeName: 'first',
      firstShow: true,
      show: false,
      height: '600',
      userinfo: null,
      bigqrcodeurl: '',
      defaultImg:
        'this.src="' + require('@/assets/img/defaultheadpic.png') + '"',
      isResume: false,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    change(item) {
      this.$router.push({ path: '/user/authentication', query: { id: item } });
    },
    changeregister(item) {
      this.$router.push({
        path: '/user/authenticationreg',
        query: { id: item },
      });
    },
   
    // 根据完善信息id进行判断是否进入完善页面
    getCandidateId() {
      candidateId().then((res) => {
        console.log(res.data.result);
        if (res.data.result !== null && res.data.result > 0) {
          this.isResume = true;
          this.candidateId = res.data.result;
        }
      });
    },
    applyrealname() {
      this.$message('请至牛豆豆APP进行实名认证');
    },
    onusergetcurruserinfo() {
      var that = this;
      usergetcurruserinfo({}).then((res) => {
        if (res.data.code === 1) {
            that.userinfo=res.data.result;
        }
      });
    },
    onutilsgetuserqrcodebase64() {
      let params = { desUId: getCookieByName('user_id') };
      utilsgetuserqrcodebase64(params).then((res) => {
        if (res.data.result !== null) {
          this.bigqrcodeurl = 'data:image/jpeg;base64,' + res.data.result;
        }
      });
    },
    ongetuserinfo() {
      usergetcurruserinfo().then((res) => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
          if (that.userinfo.userAvatar) {
            that.userAvatar = that.userinfo.userAvatar;
          }
          if (that.userinfo.userNickName) {
            that.userNickName = this.userinfo.userNickName;
          }
        }
      });
    },
  },
  mounted() {
    screenLeft;
    this.onusergetcurruserinfo();
    this.onutilsgetuserqrcodebase64();
    this.getCandidateId();
  },
};
</script>
<style  scoped >
.dialogs .el-input {
  width: 200px;
  margin-right: 10px;
}
.clear {
  clear: both;
}

body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.account-nav li a i.card,
.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  color: #404040;
  font-style: normal;
  margin-left: 10px;
}
.accountset .item-block .title em a {
  color: #0d7bff;
}

.accountset .info-cnt {
  padding: 10px 0;
}

.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb1 {
  float: left;
  margin: 20px 6px 5px 20px;
  width: 253px;
  height: 295px;
  background-color: #fafbfc;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 3px 8px 0 rgba(47, 24, 24, 0.1);
  transition: all 0.2s linear;
}
.accountset .content-rb1:hover {
  transform: translate3d(0, -2px, 0);
}
.accountset .content-rb1 .rb1-ico {
  background-image: url('../../../assets/img/auth-index-ico.png');
  background-repeat: no-repeat;
}
.accountset .content-rb1 > div:nth-of-type(1) {
  position: absolute;
  top: 0;
  right: 0;
}
.accountset .content-rb1 > div:nth-of-type(2) {
  width: 95px;
  height: 75px;
  margin: auto;
  margin-top: 55px;
  /* border: 1px solid red; */
}
.accountset .content-rb1 > p:nth-of-type(1) {
  position: absolute;
  font-weight: 500;
  top: 140px;
  left: 95px;
  font-size: 16px;
}
.accountset .content-rb1 > p:nth-of-type(2) {
  width: 208px;
  position: absolute;
  font-weight: 100;
  top: 177px;
  left: 22px;
  font-size: 10px;
  /* border: 1px solid red; */
  text-align: center;
  font-size: 13px;
}
.accountset .content-rb1 > a {
  display: block;
  width: 195px;
  height: 40px;
  position: absolute;
  font-size: 10px;
  color: #fff;
  cursor: pointer;
  top: 215px;
  left: 30px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  /* border: 1px solid #0d7bff; */
  background-image: linear-gradient(260deg, #5aa5ff, #0d7bff);
}
.accountset .content-right a {
  padding-bottom: 0;
}
.accountset .title.intotitle {
  border-bottom: none;
}
.title h2 {
  display: inline-block;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 32px;
  font-weight: 400;
  position: relative;
  padding-left: 10px;
}
.title.intotitle h2::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 19px;
  border-radius: 2px;
  background-color: #268eff;
  left: 0;
  top: 8px;
}

</style>
