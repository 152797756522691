<template>
  <div class="accountset enterpriselist">
        <div class="btnaddcompany">
                    <el-button type="primary"  plain ><i
                        class="el-icon-circle-plus-outline el-icon--left"></i>发布</el-button>
                  </div>
    <el-tabs  v-model="activeName">
      <el-tab-pane label="已发布" name="first">
        
        <template v-if="secondhouselistdata.length>=1">
          <el-table :data="secondhouselistdata" border style="width: 100%">
            <el-table-column label="照片" align="center">
              <template width="40" slot-scope="scope">
                <el-image v-if="scope.row.houseImg!=''" :src="scope.row.houseImg"
                  style="width: 60px; height: 60px; cursor:pointer;" @click="editimg(scope.row.houseImg)"></el-image>
                <el-image v-if="scope.row.houseImg===''" :src="spareimg"
                  style="width: 60px; height: 60px; cursor:pointer;" @click="editimg(spareimg)"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="communityName" label="小区名称" width="250" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价" align="center"></el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="detail(scope.row.id)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="secondhouselistdata.length==0">
          <div class="empty">
            <i class="icon el-icon-sunrise"></i>
            <span>暂无信息</span>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>
      <el-dialog title="图片查看" :visible.sync="imgsVisible" width="40%">
        <div style="display: flex;justify-content: center;">
          <el-image :src="imgs" fit="scale-down" lazy style="margin: 20px auto; max-width:400px;max-height:400px">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </el-dialog>

  </div>

</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import rentinghouselist from './rentinghouselist';
import { secondownerlist } from '@/api/house';
import Cookies from 'js-cookie';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
// import {

// } from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'secondhouselist',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
    rentinghouselist: rentinghouselist,
  },
  data() {
    return {
      activeName: 'first',
      spareimg: require('@/assets/img/sechouse1.png'),
      secondhouselistdata: [],
      imgs: '',
      imgsVisible: false,
      sellparam: {
        PageIndex: 1,
        PageSize: 10,
        kw: '',
        ScreenDataList: [],
      },
    };
  },
  methods: {
    // 个人发布的二手房列表
    getsell() {
      secondownerlist(this.sellparam).then((res) => {
        this.secondhouselistdata = res.data.result.list;
      });
    },
    detail(val) {
      let text = this.$router.resolve({
        path: '/secondhouse/housedetail',
        query: { id: val },
      });
      window.open(text.href, '_blank');
    },
    editimg(val) {
      this.imgs = val;
      this.imgsVisible = true;
    },
  },
  created() {},
  mounted() {
    this.getsell();
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 0;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb .property-box {
  margin-bottom: 20px;
  background-image: linear-gradient(196deg, #72b2ff, #0074ff);
  border-radius: 4px;
}

.operation,
.setasdefault {
  cursor: pointer;
}
.setasdefault >>> .el-button:hover,
.setasdefault >>> .el-button {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}
.operation .vertical {
  cursor: default;
  margin: 0 6px;
}
 .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
</style>
