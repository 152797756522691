<template>
  <div class="accountset rentinghouselist">
    <div class="btnaddcompany">
      <el-button type="primary" plain><i class="el-icon-circle-plus-outline el-icon--left"></i>发布</el-button>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="已发布" name="first">
        <template v-if="rentinghouselistdata.length>=1">
          <el-table :data="rentinghouselistdata" border style="width: 100%">
            <el-table-column label="照片" align="center">
              <template width="40" slot-scope="scope">
                <el-image v-if="scope.row.houseImg!=''" :src="scope.row.houseImg"
                  style="width: 60px; height: 60px; cursor:pointer;" @click="editimg(scope.row.houseImg)"></el-image>
                <el-image v-if="scope.row.houseImg===''" :src="spareimg"
                  style="width: 60px; height: 60px; cursor:pointer;" @click="editimg(spareimg)"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="communityName" label="小区名称" width="250" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="rent" label="租金" align="center"></el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="detail(scope.row.id)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="rentinghouselistdata.length==0">
          <div class="empty">
            <i class="icon el-icon-sunrise"></i>
            <span>暂无信息</span>
          </div>
        </template>

      </el-tab-pane>
    </el-tabs>
    <el-dialog title="图片查看" :visible.sync="imgsVisible" width="40%">
      <div style="display: flex;justify-content: center;">
        <el-image :src="imgs" fit="scale-down" lazy style="margin: 20px auto; max-width:400px;max-height:400px">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import { leaseownerlist } from '@/api/house';
export default {
  name: 'rentinghouselist',
  components: {},
  data() {
    return {
      rentinghouselistdata: [],
      spareimg: require('@/assets/img/sechouse1.png'),
      imgs: '',
      imgsVisible: false,
      activeName: 'first',
      selfparam: {
        pageIndex: 1,
        pageSize: 10,
        kw: '',
        screenDataList: [],
      },
    };
  },
  methods: {
    //个人出租列表
    getself() {
      leaseownerlist(this.selfparam).then((res) => {
        this.rentinghouselistdata = res.data.result.list;
      });
    },
    detail(val) {
      let text = this.$router.resolve({
        path: '/rentinghouse/rentinghousedetail',
        query: { id: val },
      });
      window.open(text.href, '_blank');
    },
    editimg(val) {
      this.imgs = val;
      this.imgsVisible = true;
    },
  },
  created() {},
  mounted() {
    this.getself();
  },
};
</script>
<style scoped>
.content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.btnaddcompany {
  position: absolute;
  right: 0;
  z-index: 100;
}
.btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
</style>