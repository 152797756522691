import http from '@/utils/http'

//二手房拥有者房源列表
export const secondownerlist = (params) =>{
    return http.post('/house/secondownerlist',params)
}
// 租赁拥有者房源列表
export const leaseownerlist = (params) =>{
    return http.post('/house/leaseownerlist',params)
}
